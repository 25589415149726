import React, { useState, useRef } from "react";
import { Link } from "gatsby";
import Fade from "react-reveal/Fade";
import Scrollspy from "react-scrollspy";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { Icon } from "react-icons-kit";
import { menu } from "react-icons-kit/feather/menu";
import { x } from "react-icons-kit/feather/x";
import Logo from "common/components/UIElements/Logo";
import Button from "common/components/Button";
import Container from "common/components/UI/Container";
import useOnClickOutside from "common/hooks/useOnClickOutside";
import NavbarWrapper, { MenuArea, MobileMenu } from "./navbar.style";
import LogoImage from "common/assets/image/appModern/logo-white.png";
import LogoImageAlt from "common/assets/image/appModern/logo.png";

const Navbar = () => {
  // const data = useStaticQuery(graphql`
  //   query {
  //     appModernJson {
  //       navbar {
  //         logo {
  //           publicURL
  //         }
  //         navMenu {
  //           id
  //           label
  //           path
  //           offset
  //         }
  //       }
  //     }
  //   }
  // `);

  const navbarData = {
    navbar: {
      logo: "../../assets/image/appModern/logo.png",
      navMenu: [
        { id: 1, label: "Home", path: "/", offset: "84" },
        { id: 2, label: "Services", path: "#features", offset: "81" },
        { id: 3, label: "Team Members", path: "#teammember", offset: "81" },
        { id: 4, label: "Our Office", path: "#testimonial", offset: "81" },
        { id: 5, label: "EOR", path: "/eor", offset: "81" },
      ],
    },
  };
  const { navMenu } = navbarData.navbar;
  const [state, setState] = useState({
    search: "",
    searchToggle: false,
    mobileMenu: false,
  });

  const searchRef = useRef(null);
  useOnClickOutside(searchRef, () =>
    setState({ ...state, searchToggle: false })
  );

  const toggleHandler = (type) => {
    if (type === "search") {
      setState({
        ...state,
        search: "",
        searchToggle: !state.searchToggle,
        mobileMenu: false,
      });
    }

    if (type === "menu") {
      setState({
        ...state,
        mobileMenu: !state.mobileMenu,
      });
    }
  };

  const scrollItems = [];

  navMenu.forEach((item) => {
    if (item.path.startsWith("#")) {
      scrollItems.push(item.path.slice(1));
    }
  });

  const handleRemoveMenu = () => {
    setState({
      ...state,
      mobileMenu: false,
    });
  };
  // const smoothScroll = (id) => {
  //   const element = document.querySelector(id);
  //   if (!element) {
  //     console.error(`No element found for selector: ${id}`);
  //     return;
  //   }
  //   element.scrollIntoView({
  //     behavior: "smooth",
  //   });
  // };

  const SafeLink = ({ to, children, ...props }) => {
    if (to.startsWith("/")) {
      return (
        <Link to={to} {...props}>
          {children}
        </Link>
      );
    }
    if (to.startsWith("#")) {
      return (
        <AnchorLink href={to} {...props}>
          {children}
        </AnchorLink>
      );
    }
    console.log("i amreturning");
    // return (
    //   <>
    //     <Link to="/ouroffice">Our Office</Link>
    //     <Link to="/eor">EOR</Link>
    //   </>
    // ); // or some default rendering if needed
  };

  return (
    <NavbarWrapper className="navbar">
      <Container>
        <Logo
          href="/"
          logoSrc={LogoImage}
          title="Comrex"
          className="main-logo"
        />
        <Logo
          href="/"
          logoSrc={LogoImageAlt}
          title="Comrex"
          className="logo-alt"
        />
        {/* end of logo */}

        <MenuArea className={state.searchToggle ? "active" : ""}>
          {/* <ScrollSpyMenu className="menu" menuItems={navMenu} offset={-84} /> */}
          {/* <ScrollSpyMenu
            className="menu"
            menuItems={navMenu}
            offset={-84}
            renderItem={(item) => (
              <SafeLink to={item.path} offset={item.offset || -84}>
                {item.label}
              </SafeLink>
            )}
          /> */}
          <ul className="menu">
            {navMenu.map((item) => (
              <li key={item.id}>
                <SafeLink to={item.path} offset={item.offset || -84}>
                  {item.label}
                </SafeLink>
              </li>
            ))}
          </ul>

          {/* end of main menu */}

          <Button
            className="menubar"
            icon={
              state.mobileMenu ? (
                <Icon className="bar" icon={x} />
              ) : (
                <Fade>
                  <Icon className="close" icon={menu} />
                </Fade>
              )
            }
            color="#0F2137"
            variant="textButton"
            onClick={() => toggleHandler("menu")}
          />
        </MenuArea>
      </Container>

      {/* start mobile menu */}
      <MobileMenu className={`mobile-menu ${state.mobileMenu ? "active" : ""}`}>
        <Container>
          <Scrollspy
            className="menu"
            items={scrollItems}
            offset={-84}
            currentClassName="active"
          >
            {navMenu.map((menu, index) => (
              <li key={`menu_key${index}`}>
                <SafeLink
                  to={menu.path}
                  offset={menu.offset}
                  onClick={handleRemoveMenu}
                >
                  {menu.label}
                </SafeLink>
              </li>
            ))}
          </Scrollspy>
        </Container>
      </MobileMenu>
      {/* end of mobile menu */}
    </NavbarWrapper>
  );
};

export default Navbar;
